<template>
    <b-modal
            no-close-on-backdrop
            centered
            hide-header-close
            hide-footer
            ok-variant="dark"
            title="Kayıt Bilgileri"
            modal-class="modal-dark"
            v-model="isPreviewModal"
            size="lg">

        <b-tabs pills>
            <b-tab active>
                <template #title>
                    <feather-icon icon="ApertureIcon"/>
                    <span>Müşteri Şikayeti Bilgileri</span>
                </template>

                <table class="mt-2 mt-xl-0 w-100">
                    <tr class="border-bottom">
                        <th class="pb-50"><span class="font-weight-bold">Tarih</span></th>
                        <td class="pb-50">
                            <date-time-column-table :data="previewData.docDate"></date-time-column-table>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Müşteri</span></th>
                        <td class="py-50">{{ previewData.customerName }}</td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">İlgili Kişi</span></th>
                        <td class="py-50">{{ previewData.customerContactName }}</td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Konu</span></th>
                        <td class="py-50">{{ previewData.complaintSubjectName }}</td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Dönüş Süresi</span></th>
                        <td class="py-50">{{ previewData.turnTimeValue }} {{ previewData.timeValueUnit }}</td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
                        <td class="py-50">{{ previewData.description }}</td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Satış Döküman Numarası</span></th>
                        <td class="py-50"><span v-html="previewData.salesDocNumber"></span></td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Ürün Kodu</span></th>
                        <td class="py-50"><span v-html="previewData.productCode"></span></td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Lot Numarası</span></th>
                        <td class="py-50"><span v-html="previewData.lotnNumber"></span></td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Miktar</span></th>
                        <td class="py-50"><span v-html="previewData.amount"></span></td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Durum</span></th>
                        <td class="py-50">
                            <b-badge pill :variant="`light-${previewData.isCancelled === true ? 'danger' :  previewData.complaintStatusId === 'WAITING' ? 'warning' : previewData.complaintStatusId === 'CONTINUES' ? 'primary' : previewData.complaintStatusId === 'PROCESSING' ? 'primary' : 'success'}`" class="text-capitalize">
                                {{ previewData.isCancelled === true ? 'İptal' : previewData.complaintStatusId === 'WAITING' ? 'Bekliyor' : previewData.complaintStatusId === 'CONTINUES' ? 'Devam Ediyor' : previewData.complaintStatusId === 'PROCESSING' ? 'İşleme Alındı' : 'Tamamlandı' }}
                            </b-badge>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Kayıt Sahibi</span></th>
                        <td class="py-50"><span v-html="previewData.userName"></span></td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
                        <td class="py-50">
                            <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
                        <td class="py-50">
                            <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
                        </td>
                    </tr>
                    <tr>
                        <th class="pt-50"><span class="font-weight-bold">İptal Zamanı</span></th>
                        <td class="pt-50">
                            <date-time-column-table :data="previewData.cancelledOn"></date-time-column-table>
                        </td>
                    </tr>
                </table>

            </b-tab>

            <b-tab v-if="actionVisible">
                <template #title>
                    <feather-icon icon="ApertureIcon"/>
                    <span>Aksiyon Bilgileri</span>
                </template>
                <b-card no-body class="border mt-1">
                    <b-table
                            :items="actionDataList"
                            :fields="actionFields"
                            class="mb-0"
                            striped
                            responsive
                            bordered
                            hover>

                        <template #cell(createdOn)="data">
                            <date-time-column-table :data="data.value"></date-time-column-table>
                        </template>

                        <template #cell(activityActionStatus)="data">
                            <b-badge pill :variant="`light-${data.item.isCancelled === true ? 'danger' :  data.value === 'WAITING' ? 'warning' : data.value === 'CONTINUES' ? 'primary' : data.value === 'PROCESSING' ? 'primary' : 'success'}`" class="text-capitalize">
                                {{ data.value }}
                            </b-badge>
                        </template>

                    </b-table>
                </b-card>
            </b-tab>

            <b-tab v-if="documentVisible">
                <template #title>
                    <feather-icon icon="ApertureIcon"/>
                    <span>Döküman Bilgileri</span>
                </template>
                <b-card no-body class="border mt-1">
                    <b-table
                            :items="documentDataList"
                            :fields="documentFields"
                            class="mb-0"
                            striped
                            responsive
                            bordered
                            hover>

                        <template #cell(id)="data">
                            <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                        </template>

                        <template #cell(createdOn)="data">
                            <date-time-column-table :data="data.value"></date-time-column-table>
                        </template>

                        <template #cell(isCancelled)="data">
                            <boolean-column-table :data="data.value"></boolean-column-table>
                        </template>

                    </b-table>
                </b-card>
            </b-tab>
        </b-tabs>

        <div class="border-bottom my-1"></div>

        <b-row class="d-flex justify-content-end align-items-center">
            <b-button
                    class="mr-1"
                    variant="outline-secondary"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="$emit('update:is-preview-modal', false)">
                <feather-icon icon="XCircleIcon" size="16"/>
                <span class="align-middle" role="button"> Kapat</span>
            </b-button>
        </b-row>

        <Overlay :busy="busy"></Overlay>
    </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BBadge, BButton, BCard, BCol, BFormCheckbox, BFormGroup, BFormInput, BLink, BModal, BRow, BTab, BTable, BTabs} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter"
import {BooleanColumnTable, DateTimeColumnTable} from "@/components/Table";

export default {
    components: {
        DateTimeColumnTable,
        BooleanColumnTable,
        Overlay,

        BFormInput,
        BFormGroup,
        BCol,
        BFormCheckbox,
        BTable,
        BCard,
        BTab,
        BTabs,
        BRow,
        BButton,
        BModal,
        BBadge,
        BLink,
    },
    model: [
        {
            prop: 'isPreviewModal',
            event: 'update:is-preview-modal',
        },
        {
            prop: 'dataRecord',
            event: 'update:data-record',
        }
    ],
    props: {
        isPreviewModal: {
            type: Boolean,
            required: true,
        },
        dataRecord: {
            type: Object,
            required: true,
        },
    },
    setup(props, {emit}) {
        const toast = useToast()
        const busy = ref(false)
        const previewData = ref({});
        const actionDataList = ref([])
        const actionVisible = ref(true)
        const documentDataList = ref([])
        const documentVisible = ref(true)

        watch(() => props.dataRecord, (dataValue) => {
            if (dataValue.id !== undefined && props.isPreviewModal) {
                busy.value = true
                store.dispatch('store/previewItem', {id: dataValue.id}).then(response => {
                    previewData.value = response.data.data
                }).catch(error => {
                    toastMessage(toast, 'danger', error.response.data.message)
                    if (error.response.status === 403) {
                        emit('update:is-preview-modal', false)
                        emit('update:data-record', {})
                    }
                }).finally(message => {
                    busy.value = false
                })

                busy.value = true
                store.dispatch('store/fetchActionList', {customerComplaintId: dataValue.id}).then(response => {
                    actionDataList.value = response.data.data
                }).catch(error => {
                    if (error.response.status === 403) {
                        actionVisible.value = false
                    } else {
                        toastMessage(toast, 'danger', error.response.data.message)
                    }
                }).finally(() => {
                    busy.value = false
                })

                busy.value = true
                store.dispatch('store/fetchDocumentList', {customerComplaintId: dataValue.id}).then(response => {
                    documentDataList.value = response.data.data
                }).catch(error => {
                    if (error.response.status === 403) {
                        documentVisible.value = false
                    } else {
                        toastMessage(toast, 'danger', error.response.data.message)
                    }
                }).finally(() => {
                    busy.value = false
                })
            }
        })

        const actionFields = [
            {key: 'createdOn', label: 'Tarih'},
            {key: 'userName', label: 'Kullanıcı'},
            {key: 'description', label: 'Açıklama'},
            {key: 'customerComplaintStatusName', label: 'Durum'},
        ]

        const documentFields = [
            {key: 'name', label: 'İsim', sortable: true},
            {key: 'fileType', label: 'Tür', sortable: true},
            {key: 'fileSize', label: 'Boyut', sortable: true},
            {key: 'createdOn', label: 'Tarih', sortable: true},
            {key: 'userName', label: 'Kullanıcı', sortable: true},
            {key: 'isCancelled', label: 'İptal', sortable: true},
        ]

        return {
            busy,
            previewData,
            actionFields,
            actionDataList,
            actionVisible,

            documentFields,
            documentDataList,
            documentVisible,

            recordStatusFormat,
            recordStatusVariant
        }
    },
}
</script>
